import React, { createRef, useState, useEffect, useRef } from "react";
import Image from "next/image";
import MainMenu, { MainMenuItems } from "components/Menu/MainMenu";
import PreviewAlert from "components/PreviewAlert";
import FooterMenu from "components/Menu/FooterMenu";
import NodeBannerList from "components/Banner/NodeBannerList";
import Branding from "components/Branding";
import { ResizeProvider } from "contexts/ResizeContext";
import BasicBlock from "components/Block/BasicBlock";
import SocialNetworksMenu, {
  SocialNetworksIconMenu,
} from "components/Menu/SocialNetworksMenu";
import QuickAccessDropdownMenu from "components/Menu/QuickAccessDropdownMenu";
import SearchForm from "components/Search/SearchForm";
import useTranslation from "next-translate/useTranslation";
import LocalesDropdown from 'components/LocalesDropdown';

export default function Layout({ children, globals, banners, home = false }) {
  const mainRef = createRef();
  const subMenuRef = useRef();
  const searchBarRef = useRef();
  const searchButtonRef = useRef();
  const mobileSearchButtonRef = useRef();
  const { t } = useTranslation("common");
  const [submenu, setSubmenu] = useState(null);
  const [searchBarOpen, setSearchBarOpen] = useState(false);

  useEffect(() => {
    if (submenu !== null && searchBarOpen === true) {
      setSearchBarOpen(false);
    }
  }, [submenu]);

  useEffect(() => {
    if (searchBarOpen !== false && submenu !== null) {
      setSubmenu(null);
    }
  }, [searchBarOpen]);

  // Mount == open
  useEffect(() => {
    const keyDownEventHandler = (event) => {
      switch (event.key) {
        case "Escape":
          setSearchBarOpen(false);
          break;
        default:
          break;
      }
    };

    const mouseDownEventHandler = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target) &&
        searchButtonRef.current &&
        !searchButtonRef.current.contains(event.target) &&
        mobileSearchButtonRef.current &&
        !mobileSearchButtonRef.current.contains(event.target)
      ) {
        setSearchBarOpen(false);
      }

      return undefined;
    };

    if (typeof document !== "undefined") {
      document.addEventListener("keydown", keyDownEventHandler);
      document.addEventListener("mousedown", mouseDownEventHandler);

      return () => {
        document.removeEventListener("keydown", keyDownEventHandler);
        document.removeEventListener("mousedown", mouseDownEventHandler);
      };
    }
    return undefined;
  }, []);

  return (
    <ResizeProvider>
      <PreviewAlert />
      <header className="z-10">
        <div className="relative">
          <div
            className="hidden md:absolute md:block right-0 inset-y-0 w-2/3 lg:w-1/2 xl:w-1/3 bg-secondary"
            aria-hidden="true"
          />
          {submenu !== null && (
            <div
              ref={subMenuRef}
              className="absolute hidden md:block bottom-0 translate-y-full z-10 bg-secondary-light w-screen shadow-lg"
            >
              <div className="container mx-auto py-12 lg:pt-32">
                <MainMenuItems {...submenu} depth={2} />
              </div>
            </div>
          )}
          {searchBarOpen !== false && (
            <div
              ref={searchBarRef}
              className="absolute bottom-0 translate-y-full z-10 bg-secondary-light w-screen shadow-lg"
            >
              <div className="container mx-auto py-12 lg:pt-32">
                <div className="w-4/5 lg:w-2/3 mx-auto">
                  <SearchForm
                    closeSearch={() => setSearchBarOpen(false)}
                    autoFocus
                  />
                </div>
              </div>
            </div>
          )}
          <div className="relative container px-4 mx-auto z-10">
            <div className="flex">
              <div className="flex-1 py-4 lg:py-6">
                <Branding home={home} />
              </div>
              <div className="hidden md:flex flex-initial flex-col justify-center lg:justify-start py-4 relative bg-secondary text-white w-2/3 lg:w-1/2 2xl:w-2/5">
                <div className="relative flex items-center justify-end divide-x -mx-4 divide-white">
                  <div className="px-4">
                    {globals?.menus?.socialNetworks && (
                      <SocialNetworksIconMenu
                        menu={globals.menus.socialNetworks}
                      />
                    )}
                  </div>
                  <div className="px-4">
                    {globals?.menus?.quickAccess && (
                      <QuickAccessDropdownMenu
                        menu={globals.menus.quickAccess}
                      />
                    )}
                  </div>
                   <div className="px-4">
                    <LocalesDropdown />
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:absolute lg:top-1/2 lg:left-auto right-0 lg:w-2/3 xl:w-3/5 2xl:w-1/2 lg:px-10 md:py-10 md:bg-secondary-dark text-white z-20 md:translate-y-0">
              <div
                className="hidden md:block lg:hidden absolute left-1/2 inset-y-0 w-screen -translate-x-1/2 bg-secondary-dark"
                aria-hidden="true"
              />
              {globals?.menus?.main && (
                <MainMenu
                  menu={globals.menus.main}
                  depth={2}
                  setSubmenu={setSubmenu}
                  globals={globals}
                  searchBarOpen={searchBarOpen}
                  setSearchBarOpen={setSearchBarOpen}
                  searchButtonRef={searchButtonRef}
                  mobileSearchButtonRef={mobileSearchButtonRef}
                  subMenuRef={subMenuRef}
                />
              )}
            </div>
          </div>
        </div>
        {banners?.length > 0 ? (
          <NodeBannerList nodes={banners} scrollDownRef={mainRef} />
        ) : (
          <figure className="relative h-32 z-0">
            <Image
              src="/images/banner.jpg"
              width="1800"
              height="135"
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              alt="Bannière Commune de Chooz"
            />
          </figure>
        )}
      </header>
      <main ref={mainRef}>{children}</main>
      <footer className="relative">
        <div className="bg-secondary-light py-12">
          <div className="container mx-auto px-4">
            <div className="flex items-center gap-8">
              <h2 className="hidden lg:block flex-initial w-1/3 text-3xl font-serif">
                {t("socialnetworks.title")}
              </h2>
              <div className="flex-1">
                {globals?.menus?.socialNetworks && (
                  <SocialNetworksMenu menu={globals.menus.socialNetworks} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="py-20 bg-secondary-dark text-white">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="hidden lg:block pr-10 xl:pr-32">
                <Image
                  src="/images/logo-map.png"
                  width="303"
                  height="240"
                  layout="responsive"
                  alt="Logo de la commune de Chooz"
                />
              </div>
              {globals?.blocks?.address && (
                <BasicBlock
                  data={globals.blocks.address}
                  className="footer-text"
                />
              )}
              {globals?.blocks?.openingHours && (
                <BasicBlock
                  data={globals.blocks.openingHours}
                  className="footer-text"
                />
              )}
            </div>
          </div>
        </div>
        <div className="bg-secondary-dark text-white text-sm">
          <div className="container mx-auto px-4 border-t border-secondary-light border-opacity-50 pt-8 pb-20">
            <div className="flex flex-col xl:flex-row gap-4">
              <div className="flex-1">
                {globals?.menus?.footer && (
                  <FooterMenu menu={globals.menus.footer} />
                )}
              </div>
              <div className="flex-initial xl:text-right">
                {t("copyright.text")}{" "}
                <a
                  href="https://www.isics.fr"
                  target="blank"
                  className="text-primary hover:text-primary-light transition-colors"
                >
                  Isics
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </ResizeProvider>
  );
}
