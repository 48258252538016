import React from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon, CursorClickIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";
import NextLink from "components/NextLink";

export default function QuickAccessDropdownMenu({ menu }) {
  const { t } = useTranslation("common");
  const isNotAlias = /(?:\/node\/)/;

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button
            className={classNames(
              "flex items-center space-x-2 transition-colors",
              open
                ? "text-primary hover:text-primary-light"
                : "text-white hover:text-primary"
            )}
          >
            <CursorClickIcon className="h-4 w-4" aria-hidden="true" />
            <span>{t("quickaccess.button")}</span>
            <ChevronDownIcon
              className={classNames(
                "h-4 w-4 text-primary transition-all",
                open && "-rotate-180"
              )}
              aria-hidden="true"
            />
          </Menu.Button>
          {open && (
            <Menu.Items
              as="div"
              className="absolute top-full right-0 w-72 border-t-8 border-primary text-right mt-2 -mr-4 py-2 text-black bg-white z-40 shadow-md"
              static
            >
              {menu.map((link) => {
                // We assume that non translated pages has no alias
                if (link.url.match(isNotAlias) !== null) {
                  return null;
                }

                return (
                  <Menu.Item key={link.title}>
                    {({ active }) => (
                      <NextLink
                        href={link.url}
                        className={classNames(
                          "block py-2 px-4",
                          active && "bg-secondary-light"
                        )}
                      >
                        {link.title}
                      </NextLink>
                    )}
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          )}
        </>
      )}
    </Menu>
  );
}
