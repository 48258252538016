import React from "react";
import { Menu } from "@headlessui/react";
import { ChevronUpIcon, CursorClickIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";
import NextLink from "components/NextLink";

export default function MainMenuQuickAccessDropdownMenu({ menu }) {
  const { t } = useTranslation("common");
  const isNotAlias = /(?:\/node\/)/;

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button
            className={classNames(
              "w-full py-6 flex items-center space-x-2 transition-colors",
              open
                ? "text-primary hover:text-primary-light"
                : "text-white hover:text-primary"
            )}
          >
            <CursorClickIcon className="h-4 w-4" aria-hidden="true" />
            <span className="flex-1 text-left">{t("quickaccess.button")}</span>
            <ChevronUpIcon
              className={classNames(
                "h-4 w-4 text-primary transition-all",
                open && "-rotate-180"
              )}
              aria-hidden="true"
            />
          </Menu.Button>
          <Menu.Items
            as="div"
            className="absolute flex h-[calc(100vh_-_72px)] flex-col bottom-full justify-end inset-x-0 bg-secondary text-white z-40 py-4"
          >
            <div
              className="absolute inset-y-0 left-1/2 w-screen bg-secondary transform -translate-x-1/2"
              aria-hidden="true"
            />
            <div className="relative">
              {menu.map((link) => {
                // We assume that non translated pages has no alias
                if (link.url.match(isNotAlias) !== null) {
                  return null;
                }

                return (
                  <Menu.Item as="div" key={link.title}>
                    {({ active }) => (
                      <NextLink
                        href={link.url}
                        className={classNames(
                          "block py-4",
                          active && "text-primary"
                        )}
                      >
                        {link.title}
                      </NextLink>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}
