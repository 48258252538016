import React from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";
import Link from "next/link";
import i18nConfig from "i18n.json";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

export default function LocalesDropdown() {
  const { locales } = i18nConfig;
  const { lang } = useTranslation();
  const { asPath } = useRouter();

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button
            className={classNames(
              "flex items-center space-x-2 transition-colors",
              open
                ? "text-primary hover:text-primary-light"
                : "text-white hover:text-primary"
            )}
          >
            <span className="uppercase">{lang}</span>
            <ChevronDownIcon
              className={classNames(
                "h-4 w-4 text-primary transition-all",
                open && "-rotate-180"
              )}
              aria-hidden="true"
            />
          </Menu.Button>
          <Menu.Items
            as="ul"
            className="flex flex-row divide-x absolute top-full right-0 border-t-8 border-primary text-right mt-2 -mr-4 py-2 bg-white z-50 shadow-md"
          >
            {locales.map((lng) => {
              if (lng === lang) return null;

              return (
                <Menu.Item as="li" key={lng}>
                  {({ active }) => (
                    <Link href={asPath} locale={lng} passHref>
                      <a
                        className={classNames(
                          "block py-1 px-6 uppercase text-black",
                          active && "bg-secondary-light"
                        )}
                      >
                        {lng}
                      </a>
                    </Link>
                  )}
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}

export function LocalesMenu() {
  const { locales } = i18nConfig;
  const { lang } = useTranslation();
  const { asPath } = useRouter();

  return (
    <ul className="flex gap-2">
      {locales.map((lng) => {
        return (
          <li key={lng}>
            <div
              className={classNames(
                "flex justify-center items-center overflow-hidden rounded-full p-3",
                lng === lang ? "bg-secondary" : "bg-secondary-dark"
              )}
            >
              <Link href={asPath} locale={lng} passHref>
                <a className="flex justify-center items-center uppercase text-white h-5 w-5">
                  {lng}
                </a>
              </Link>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
