import React from "react";
import Link from "next/link";

export default function FooterMenu({ menu }) {
  return (
    menu && (
      <ul>
        {menu.map((item) => {
          return (
            <li key={item.id}>
              <Link href={item.url} prefetch={false} passHref>
                <a className="hover:text-primary transition-colors">
                  {item.title}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    )
  );
}
