import React from "react";
import Link from "next/link";

export default function NextLink({ href, children, ...props }) {
  return (
    <Link href={href} prefetch={false} passHref>
      <a {...props}>{children}</a>
    </Link>
  );
}
