import React from "react";
import Image from "next/image";

export default function NodeBannerList({ nodes }) {
  const firstActiveBanner = nodes[0];

  return (
    <section className="relative overflow-hidden">
      <ul>
        <li
          key={firstActiveBanner.id}
          className="relative aspect-w-13 aspect-h-4"
        >
          <figure>
            <Image
              src={`${firstActiveBanner.field_image.image_style_uri.hq}`}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              alt={firstActiveBanner.field_image.resourceIdObjMeta.alt}
            />
            <figcaption className="sr-only">
              {firstActiveBanner.title}
            </figcaption>
          </figure>
        </li>
      </ul>
      <div
        className="bg-decoration-header bg-cover absolute block bottom-0 -translate-x-1/2 translate-y-3/4"
        style={{ width: "29vw", height: "29vw" }}
        aria-hidden="true"
      />
      <div
        className="bg-decoration-header bg-cover absolute block top-0 right-0 translate-x-3/4 -translate-y-3/4"
        style={{ width: "29vw", height: "29vw" }}
        aria-hidden="true"
      />
    </section>
  );
}
