import React from "react";
import Link from "next/link";
import BrandingContent from "components/Branding/BrandingContent";

export default function Branding({ home }) {
  return home ? (
    <BrandingContent home />
  ) : (
    <Link href="/" prefetch={false} passHref>
      <a className="no-underline">
        <BrandingContent />
      </a>
    </Link>
  );
}
