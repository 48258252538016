import Body from "components/Body";
import React from "react";

export default function BasicBlock({ data, ...props }) {
  return (
    <div {...props}>
      <h2 className="text-3xl font-serif text-secondary-light text-opacity-50 mb-8">
        {data.info}
      </h2>
      <div className="prose prose-inverse max-w-none">
        <Body value={data.body.value} />
      </div>
    </div>
  );
}
