import React, { createRef, useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import classNames from "lib/classNames";
import { useRouter } from "next/router";
import i18nConfig from "i18n.json";
import useTranslation from "next-translate/useTranslation";

export default function SearchForm({ closeSearch, autoFocus = false }) {
  const { lang, t } = useTranslation("common");
  const { defaultLocale } = i18nConfig;
  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const router = useRouter();
  const searchRef = createRef();

  const nodeTypes = {
    "node--news": "news.type",
    "node--event": "event.type",
    "node--page": "page.type",
    "node--gallery": "gallery.type",
  };

  useEffect(() => {
    if (autoFocus && searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  useEffect(() => {
    fetch("/api/search/filter", {
      method: "POST",
      body: JSON.stringify({
        params: {
          filter: {
            fulltext: query,
            langcode: lang || defaultLocale,
          },
          page: {
            limit: 10,
          },
        },
        locale: lang,
        defaultLocale,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setResults(data);
      });
  }, [query]);

  useEffect(() => {
    if (selected !== null) {
      router.push(selected);
      closeSearch();
    }
  }, [selected]);

  return (
    <Combobox value={null} onChange={setSelected}>
      <div className="relative">
        <Combobox.Input
          ref={searchRef}
          onChange={(event) => setQuery(event.target.value)}
          placeholder={t("search.placeholder")}
          className="pl-6 pr-2 py-4 w-full mx-auto appearance-none bg-white text-secondary-dark shadow-md focus:outline-none border border-transparent focus:border-primary"
          autoComplete="off"
        />
        <div className="absolute w-full z-30">
          <div
            className="absolute inset-y-0 left-1/2 w-full bg-white transform -translate-x-1/2"
            aria-hidden="true"
          />
          <Combobox.Options className="relative border-t-8 border-primary">
            {results.length > 0 ? (
              <>
                {results.map((result) => {
                  return (
                    <Combobox.Option
                      key={result.id}
                      value={result.path.alias}
                      className={({ active }) =>
                        classNames(
                          active && "bg-primary-light",
                          "flex p-2 pl-6 cursor-pointer"
                        )
                      }
                    >
                      <span className="flex-1">{result.title}</span>
                      <span className="text-sm text-secondary">
                        {t(nodeTypes[result.type])}
                      </span>
                    </Combobox.Option>
                  );
                })}
              </>
            ) : (
              <p>{t("utils.empty")}</p>
            )}
          </Combobox.Options>
        </div>
      </div>
    </Combobox>
  );
}
