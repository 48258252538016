import React from "react";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import IconFacebook from "components/Icons/IconFacebook";
import IconInstagram from "components/Icons/IconInstagram";
import IconTwitter from "components/Icons/IconTwitter";
import classNames from "lib/classNames";
import Link from "next/link";
import getSocialFromUrl from "lib/getSocialFromUrl";
import HeroIcon from "components/HeroIcon";

export default function SocialNetworksMenu({ menu }) {
  const components = {
    facebook: IconFacebook,
    instagram: IconInstagram,
    twitter: IconTwitter,
    other: ExternalLinkIcon,
  };

  return (
    <ul className="flex flex-col md:flex-row flex-wrap md:items-center gap-8">
      {menu.map((link) => {
        const hasHeroIcon = link.options?.attributes["data-icon-name"] || false;
        const social = getSocialFromUrl(link.url);
        const IconComponent = components[social];
        return (
          <li key={link.title} className="flex-1">
            <Link href={link.url} prefetch={false} passHref>
              <a
                className="group flex items-center gap-4 text-black"
                target={link.options.attributes.target}
              >
                <span className="inline-block p-6 bg-primary text-primary-darker rounded-full group-hover:bg-primary-dark transition-colors">
                  {hasHeroIcon ? (
                    <HeroIcon
                      name={link.options.attributes["data-icon-name"]}
                      type={link.options.attributes["data-icon-type"]}
                      className="w-5 h-5"
                    />
                  ) : (
                    <IconComponent className="w-4 h-4" />
                  )}
                </span>
                <span className="underline underline-offset-4 decoration-transparent transition-colors group-hover:decoration-primary">
                  {link.title}
                </span>
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export function SocialNetworksIconMenu({ menu, theme = "default" }) {
  const components = {
    facebook: IconFacebook,
    instagram: IconInstagram,
    twitter: IconTwitter,
    other: ExternalLinkIcon,
  };

  const themes = {
    default: {
      gap: "gap-2",
      link: "bg-secondary hover:text-primary",
    },
    bordered: {
      gap: "gap-4",
      link: "border border-secondary hover:border-primary",
    },
  };

  return (
    <ul className={classNames("flex items-center", themes[theme].gap)}>
      {menu.map((link) => {
        const hasHeroIcon = link.options?.attributes["data-icon-name"] || false;
        const social = getSocialFromUrl(link.url);
        const IconComponent = components[social];

        return (
          <li key={link.title}>
            <Link href={link.url} prefetch={false} passHref>
              <a
                className={classNames(
                  "flex items-center gap-1 justify-center text-white transition-colors",
                  themes[theme].link,
                  link?.options?.attributes?.class[0] ? link.options.attributes.class[0] : "h-14 px-4 md:p-3 md:h-auto"
                )}
                target={link.options.attributes.target}
                title={link.title}
              >
                {hasHeroIcon ? (
                  <HeroIcon
                    name={link.options.attributes["data-icon-name"]}
                    type={link.options.attributes["data-icon-type"]}
                    className="w-5 h-5"
                  />
                ) : (
                  <IconComponent className="w-4 h-4 inline-block" />
                )}
                <span
                  className={classNames(
                    (hasHeroIcon || social !== "other") && "sr-only"
                  )}
                >
                  {link.title}
                </span>
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
