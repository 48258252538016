import React from "react";

export default function IconFacebook({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      height="512"
      width="512"
      className={className}
    >
      <path
        fill="currentColor"
        d="M304 96h80V0h-80c-61.757 0-112 50.243-112 112v48h-64v96h64v256h96V256h80l16-96h-96v-48c0-8.673 7.327-16 16-16z"
      />
    </svg>
  );
}
