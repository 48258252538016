import React from "react";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";

export default function BrandingContent({ home = false }) {
  const { t } = useTranslation("common");

  return (
    <div className="flex items-center sm:space-x-4">
      <div className="flex-initial">
        <Image
          src="/images/logo.png"
          width={151}
          height={74}
          alt={t("branding.alt")}
        />
        {home && <h1 className="sr-only">{t("branding.title")}</h1>}
      </div>
    </div>
  );
}
