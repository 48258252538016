import React, { useContext, useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";
import { ResizeContext } from "contexts/ResizeContext";
import { SearchIcon } from "@heroicons/react/solid";
import { LocalesMenu } from "components/LocalesDropdown";
import { SocialNetworksIconMenu } from "components/Menu/SocialNetworksMenu";
import { Transition } from "@headlessui/react";
import MainMenuQuickAccessDropdownMenu from "components/Menu/MainMenuQuickAccessDropdownMenu";

export default function MainMenu({
  menu,
  depth = null,
  setSubmenu = null,
  globals = null,
  searchBarOpen = null,
  setSearchBarOpen = null,
  searchButtonRef = null,
  mobileSearchButtonRef = null,
  subMenuRef = null,
}) {
  const mobileBreakpoints = ["sm", "md"];
  const { breakpoint } = useContext(ResizeContext);
  const [open, setOpen] = useState(false);

  const closeByDisplay = () => {
    if (mobileBreakpoints.includes(breakpoint)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (typeof breakpoint !== "undefined") {
      setOpen(!mobileBreakpoints.includes(breakpoint));
    }
  }, [breakpoint]);

  useEffect(() => {
    if (mobileBreakpoints.includes(breakpoint)) {
      document.body.style.overflow = open ? "hidden" : "visible";
    }
  }, [open]);

  return (
    menu && (
      <div
        id="mainMenu"
        className="flex items-center md:block absolute top-4 right-0 md:top-auto md:right-auto md:relative z-10"
        aria-labelledby="mainMenuToggle"
        aria-haspopup="true"
      >
        <button
          type="button"
          ref={mobileSearchButtonRef}
          onClick={() => setSearchBarOpen(!searchBarOpen)}
          className="border md:hidden border-secondary border-opacity-50 overlay-hidden rounded-full p-3 flex justify-center hover:border-primary transition-all duration-400 cursor-pointer items-center"
        >
          <SearchIcon className="h-5 w-5 text-black" />
          <span className="sr-only">Recherche</span>
        </button>

        <button
          type="button"
          id="mainMenuToggle"
          className="p-4 md:sr-only flex justify-center items-center"
          onClick={() => setOpen(!open)}
          aria-expanded={open}
        >
          <span className="inline-block p-3 rounded-full border border-primary text-primary-darker bg-primary hover:bg-primary-dark transition-colors">
            <MenuIcon className="h-5 w-5" />
          </span>
          <span className="sr-only">Menu</span>
        </button>

        <Transition
          as="div"
          id="mainMenuWrapper"
          show={open}
          enter="transition-transform ease-in-out duration-300 md:duration-0"
          enterFrom="translate-x-full md:translate-x-0"
          enterTo="translate-x-0"
          leave="transition-transform ease-in-out duration-300 md:duration-0"
          leaveFrom="translate-x-0 md:translate-x-full"
          leaveTo="translate-x-full"
          className="flex flex-col"
        >
          <div className="py-8 md:py-0 flex-1 overflow-auto">
            <div className="absolute top-8 right-4 md:left-16 md:hidden z-50">
              <button
                type="button"
                className="inline-block p-3 bg-primary hover:bg-primary-dark rounded-full text-primary-darker transition-colors"
                onClick={() => setOpen(false)}
              >
                <XIcon className="h-5 w-5" />
                <span className="sr-only">Fermer</span>
              </button>
            </div>

            <div className="md:hidden container mx-auto px-4 mb-12">
              <LocalesMenu />
            </div>

            <nav className="container md:max-w-none mx-auto px-4 md:px-0">
              <div className="flex flex-col md:flex-row items-start md:items-center">
                <div className="flex-1 mb-12 md:mb-0">
                  <MainMenuItems
                    setSubmenu={
                      mobileBreakpoints.includes(breakpoint) ? null : setSubmenu
                    }
                    menu={menu}
                    closeParent={closeByDisplay}
                    depth={depth}
                    subMenuRef={subMenuRef}
                  />
                </div>
                <div className="flex-initial hidden md:block">
                  <button
                    type="button"
                    ref={searchButtonRef}
                    onClick={() => setSearchBarOpen(!searchBarOpen)}
                    className={classNames(
                      "flex border rounded-full h-14 w-14 justify-center hover:border-primary transition-all duration-400 items-center",
                      searchBarOpen
                        ? "border-primary bg-primary text-primary-darker"
                        : "border-secondary border-opacity-50"
                    )}
                  >
                    {searchBarOpen ? (
                      <>
                        <XIcon className="h-5 w-5" />
                        <span className="sr-only">
                          Replier la barre de recherche
                        </span>
                      </>
                    ) : (
                      <>
                        <SearchIcon className="h-5 w-5" />
                        <span className="sr-only">Recherche</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </nav>
            {globals?.menus?.socialNetworks && (
              <div className="md:hidden container md:max-w-none mx-auto px-4">
                <SocialNetworksIconMenu
                  menu={globals.menus.socialNetworks}
                  theme="bordered"
                />
              </div>
            )}
          </div>
          {globals?.menus?.quickAccess && (
            <div className="md:hidden border-t border-secondary flex-initial">
              <div className="container mx-auto px-4">
                <MainMenuQuickAccessDropdownMenu
                  menu={globals.menus.quickAccess}
                />
              </div>
            </div>
          )}
        </Transition>
      </div>
    )
  );
}

export function MainMenuItems({
  menu,
  depth,
  closeParent = null,
  level = 0,
  setSubmenu = null,
  subMenuRef = null,
}) {
  const [open, setOpen] = useState(null);
  const menuRef = useRef();
  const router = useRouter();

  const closeWithParent = () => {
    setOpen(null);
    closeParent();
  };

  // Mount == open
  useEffect(() => {
    const keyDownEventHandler = (event) => {
      switch (event.key) {
        case "Escape":
          closeWithParent();
          break;
        default:
          break;
      }
    };

    const mouseDownEventHandler = (event) => {
      if (
        // Event target is not in submenu wrapper
        subMenuRef.current &&
        !subMenuRef.current.contains(event.target) &&
        // Event target is not in menu wrapper
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        closeWithParent();
      }
    };

    if (level === 0 && typeof document !== "undefined") {
      document.addEventListener("keydown", keyDownEventHandler);
      document.addEventListener("mousedown", mouseDownEventHandler);

      return () => {
        document.removeEventListener("keydown", keyDownEventHandler);
        document.removeEventListener("mousedown", mouseDownEventHandler);
      };
    }

    return undefined;
  }, []);

  const linkEventHandler = (event) => {
    event.preventDefault();
    router.push(event.currentTarget.href);
    closeParent();
  };

  useEffect(() => {
    if (level === 0 && typeof setSubmenu === "function") {
      if (open !== null) {
        menu.forEach((item, id) => {
          if (open === id) {
            setSubmenu({
              menu: item.items,
              closeParent: closeWithParent,
              depth,
              level: level + 1,
            });
          }
        });
      } else {
        setSubmenu(null);
      }
    }
  }, [open]);

  const isNotAlias = /(?:\/node\/)/;

  return (
    <ul ref={menuRef} className={`menu-ul-${level}`}>
      {menu.map((item, id) => {
        const isOpen = open === id;
        const hasDropdown = item.items && item.items.length > 0;
        const classes = item.options.attributes
          ? item.options.attributes.class
          : [];

        // We assume that non translated pages has no alias
        if (item.url.match(isNotAlias) !== null) {
          return null;
        }

        return (
          <li
            key={item.id}
            className={classNames(`menu-li-${level}`, classes)}
            aria-haspopup={hasDropdown}
            role="menuitem"
          >
            {depth && depth > level && hasDropdown ? (
              <>
                {level === 0 && (
                  <>
                    <button
                      type="button"
                      className={classNames(
                        `group menu-button-${level}`,
                        hasDropdown && "flex items-center"
                      )}
                      onClick={() => setOpen(isOpen ? null : id)}
                      aria-expanded={isOpen}
                    >
                      <span
                        className={classNames(
                          level === 0 ? "block" : "inline-block"
                        )}
                      >
                        {item.title}
                      </span>
                      {hasDropdown && (
                        <ChevronDownIcon
                          className={classNames(
                            "md:hidden h-4 w-4 text-primary transition-all",
                            open === id && "rotate-180"
                          )}
                        />
                      )}
                    </button>
                    {isOpen && setSubmenu === null && (
                      <MainMenuItems
                        menu={item.items}
                        depth={depth}
                        closeParent={closeWithParent}
                        level={level + 1}
                      />
                    )}
                  </>
                )}
                {level > 0 && (
                  <>
                    <a
                      href={item.url}
                      className={classNames("group", `menu-a-${level}`)}
                      onClick={linkEventHandler}
                      aria-current={router.asPath === item.url ? "page" : false}
                    >
                      <span
                        className={classNames(
                          level === 0 ? "block" : "inline-block"
                        )}
                      >
                        {item.title}
                      </span>
                    </a>
                    {setSubmenu === null && (
                      <MainMenuItems
                        menu={item.items}
                        depth={depth}
                        closeParent={closeWithParent}
                        level={level + 1}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <a
                href={item.url}
                className={classNames("group", `menu-a-${level}`)}
                onClick={linkEventHandler}
                aria-current={router.asPath === item.url ? "page" : false}
              >
                <span
                  className={classNames(level === 0 ? "block" : "inline-block")}
                >
                  {item.title}
                </span>
              </a>
            )}
          </li>
        );
      })}
    </ul>
  );
}
